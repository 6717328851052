import React from "react";
import styled from "styled-components";
import {gql, useMutation} from '@apollo/client';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
`
const Button = styled.button`
  cursor: pointer;
  margin-right: 10px;
`;


const Test = () => {
    const WAITING_PATIENT = gql`
        mutation Mutation {
            testWaitingPatient
        }
    `;
    const CALL_PATIENT = gql`
        mutation Mutation {
            testCallPatient
        }
    `;

    const [waitingPatient] = useMutation(WAITING_PATIENT);
    const [callPatient] = useMutation(CALL_PATIENT);

    const onClickWaitingPatient = async () => {
        try {
            await waitingPatient();
        } catch(e) {
            console.error(e.message);
        }
    }

    const onClickCallPatient = async () => {
        try {
            await callPatient();
        } catch(e) {
            console.error(e.message);
        }
    }

    return (
        <Wrapper>
            <Button
                onClick={onClickWaitingPatient}
            >
                환자정보
            </Button>
            <Button
                onClick={onClickCallPatient}
            >
                호출하기
            </Button>
        </Wrapper>
    )
}

export default Test;


// {
//     didAttached: [],
//     didDoctorRoom: [],
//     didLowMsg: [],
//     did_calledPersonFontsize: "크게",
//     did_calledTextUsed: false,
//     did_calledVoiceUsed: false,
//     // did_createdAt: "",
//     // did_creatorId: 1,
//     did_creatorName: "",
//     did_creatorRank: "",
//     did_deleteDate: null,
//     did_doctorRoomExpression: "진료실명 - 의사명",
//     did_doctorRoomIsHorizontal: true,
//     did_doctorRoomMerge: false,
//     // did_editorId: 1,
//     // did_editorName: "이영광",
//     // did_editorRank: "원장",
//     did_erColor: "핑크색",
//     did_erColorUsed: true,
//     did_holdingColor: "초록색",
//     did_holdingColorUsed: true,
//     did_id: null,
//     // did_isDelete: false,
//     did_lowMsgUsed: true,
//     did_mediaType: "image",
//     did_monitorRatio: "1 : 1",
//     did_monitorType: "vertical",
//     did_patExpRatio1: 0,
//     did_patExpRatio2: 0,
//     did_patExpRatio3: 0,
//     did_patExpRatio4: 0,
//     did_patExpress1: "empty",
//     did_patExpress2: "empty",
//     did_patExpress3: "empty",
//     did_patExpress4: "empty",
//     did_resInfoCycle: 10,
//     did_resInfoLocation: "up",
//     did_resInfoTime: 30,
//     did_resUsed: true,
//     did_standbyPersonExpression: "00 명",
//     did_standbyPersonFontsize: "크게",
//     did_title: "",
//     did_transmitType: "always",
//     // did_uniqueId: "31692954740251TkANISVz",
//     // did_updatedAt: "2023-08-29T10:41:08.389Z"
// } ||
