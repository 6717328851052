import styled, {keyframes} from "styled-components";
import theme from "../../styles/theme";

const Slider = ($containerWidth, $length) => keyframes`
  from {
     left: 100%;
    //left: ${window.innerWidth}px;
  }
  to {
    left: ${-100 * $length}%;
    //left: ${-$containerWidth - ($length !== 1 ? window.innerWidth : 0)}px;
  }
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: 84px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 16px;
  background-color: ${theme.colors.blackColor};
`;
export const SlideList = styled.div`
  //width: ${({ $containerWidth }) => $containerWidth}px;
  width: 100vw;
  height: 100%;
  position: relative;
  right: ${-window.innerWidth}px;
  animation: ${({ $containerWidth, $length }) => Slider($containerWidth, $length)} ${({ $duration }) => $duration}s linear infinite;
  //animation-delay: 1s;
`;
export const MessageBox = styled.div`
  min-width: ${({ $width }) => $width > window.innerWidth ? '100%' : `${window.innerWidth + $width}px`};
  display: inline-block;
`;
export const MessageText = styled.span`
  font-size: 42px;
  color: ${theme.colors.whiteColor};
`;
