const colors = {
    // General
    whiteColor: "#FFFFFF",
    blackColor: "#000000",

    // Active
    activeBlue: '#2D8CFF',
    activeIndigo: '#0E2044',
    activeSkyBlue: '#2B89FF',
    deepBlueColor: '#022354',

    activePink: '#F63E90',
    activeLightOrange: '#F99F25',
    activeYellow: '#F9D025',
    activeGreen: '#4CAF50',
    activeYellowGreen: '#B5FF0D',
    activeGray: '#A2A2A2',
    activeCoral: '#FF5D5B',

    // Font
    fontGrayColor: '#B3B3B3',

    // Border
    lightGrayBorder: '#E5E5E5',
    listBorderColor: '#BBC5D5',

    // BgColor
    infoBgColor: '#454B6F',
    listBgColor: '#EEF0F3',
    lightSkyBlueColor: '#CEE2FF',
};

const theme = {
    colors
};

export default theme;
