import styled, {css} from "styled-components";
import theme from "../../styles/theme";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: ${({$flex}) => $flex};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  
  .swiper {
    height: 100%;
  }
`;
export const ImageBox = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
