import styled from "styled-components";
import theme from "../../styles/theme";

export const Wrapper = styled.div`
  width: 100vw;
  height: 84px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 16px;
  background-color: ${theme.colors.blackColor};
`;
export const SlideList = styled.div``;
export const MessageBox = styled.div`
  min-width: ${({ $width }) => $width > window.innerWidth ? '100vw' : `${window.innerWidth + $width}px`};
  display: inline-block;
  margin-right: ${({ $width }) => $width > window.innerWidth ? '100vw' : 0};
`;
export const MessageText = styled.span`
  font-size: 42px;
  color: ${theme.colors.whiteColor};
`;
