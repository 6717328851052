import React, {useContext, useEffect, useState} from "react";
import {Wrapper, Contents} from "./patient.style";
import PatientList from "./PatientList";
import PatientHeader from "./PatientHeader";
import {MainContext} from "../../pages/Main";
import ClinicIntegrationView from "./ClinicIntegrationView";
import DivideView from "./DivideView";

const PatientInfo = ({ratio}) => {
    const {didData} = useContext(MainContext);

    let [style, setStyle] = useState({
        wrapperPadding: '50px',
        flexDirection: 'row',
        alignItems: 'flex-start',
        titleMarginTop: 0
    });
    let [contentsHeight, setContentsHeight] = useState(null);

    useEffect(() => {
        setTimeout(() => { // DOM 그려진 후 높이 계산
            let infoWidth = document.getElementById("infoBox")?.clientWidth;
            let infoHeight = document.getElementById("infoBox")?.clientHeight;
            let headerHeight = document.getElementById("infoHeader")?.clientHeight;

            if (infoWidth <= 960) { // 비율 설정으로 width 가 960 이하일 때 style 세팅
                setStyle({
                    wrapperPadding: '30px',
                    flexDirection: 'column-reverse',
                    alignItems: 'flex-end',
                    titleMarginTop: 12
                });
            }

            // flex 컨테이너 height 구한 뒤 Header, margin, padding 제외한 contents 돔 높이 구하기
            setContentsHeight(infoHeight - headerHeight - 170);
        }, 100);
    }, []);

    return (
        <Wrapper
            id='infoBox'
            $flex={ratio}
            $padding={style.wrapperPadding}>
            <PatientHeader
                style={style}
            />
            <Contents
                $height={didData?.did_monitorType === 'horizontal' // ? 'calc(100vh - 234px)'
                    ? `${contentsHeight}px`
                    : `${contentsHeight}px`}
                $flexDirection={didData?.did_doctorRoomIsHorizontal ? 'row' : 'column'}>
                {didData?.did_doctorRoomIsDivide ? <DivideView contentsHeight={contentsHeight}/>
                    : didData?.did_doctorRoomMerge
                    ? <ClinicIntegrationView contentsHeight={contentsHeight}/>
                    : didData?.didDoctorRoom.map((doctors, index) => {
                        if (doctors.ddr_viewSelect) {

                            return (
                                <PatientList
                                    key={index}
                                    doctors={doctors}
                                    contentsHeight={contentsHeight}
                                />
                            );
                        }
                    })}
            </Contents>
        </Wrapper>
    )
}

export default PatientInfo;
