import {Routes, Route} from "react-router-dom";
import {AuthProvider} from "./context/AuthContext";
import Login from "./pages/Login";
import Main from "./pages/Main";
// import Video from "./pages/Video";
// import Images from "./pages/Images";
// import Reservation from "./pages/Reservation";

const LoggedOutRoutes = () => (
    <Routes>
        <Route path="/:serial" element={<Login />} />
        <Route path="/" element={<Login />} />
        {/*<Route path="/:hospitalName/:mediaType/:serial" element={<Login />} />*/}
    </Routes>
);

const LoggedInRoutes = () => (
    <Routes>
        <Route path='/:serial' element={<Main />} />
    </Routes>
);


const AppRouter = ({ isLoggedIn }) => {
    return (
        <AuthProvider isLoggedIn={isLoggedIn}>
            {isLoggedIn
                ? <LoggedInRoutes />
                : <LoggedOutRoutes />
            }
        </AuthProvider>
    )
}

export default AppRouter;
