import React, {useContext, useEffect, useState} from "react";
import {Wrapper, Contents, GrayBox, Text} from "./callScreen.style";
import {MainContext} from "../../pages/Main";

const CallScreen = ({callData}) => {
    const {didData} = useContext(MainContext);

    const [fontSize, setFontSize] = useState(124);

    useEffect(() => {
        if (didData) {
            switch (didData.did_calledPersonFontsize) {
                case '크게':
                    setFontSize(124);
                    break;
                case '중간':
                    setFontSize(112);
                    break;
                case '작게':
                    setFontSize(100);
                    break;
            }
        }
    }, [didData]);

    return (
        <Wrapper>
            <Contents>
                <GrayBox>
                    <Text $fontSize={fontSize}>
                        "{callData.PatientName.length > 8 ? `${callData.PatientName.substr(0, 8)}..` : callData.PatientName}"
                        님, {callData.DeptName}로&nbsp;오시기 바랍니다.
                    </Text>
                </GrayBox>
            </Contents>
        </Wrapper>
    )
}

export default CallScreen;
