import React from 'react';
import styled, { css } from 'styled-components';
import theme from '../../styles/theme';
import {RegularText} from "./StyledText";

const Wrapper = styled.div`
    width: ${({ $width }) => $width}px;
    margin: ${({ $margin }) => ($margin ? $margin : 0)};
`;
const ColumnTitle = styled(RegularText)`
  margin-bottom: 8px;
`;
const RowTitle = styled(RegularText)`
  width: ${({$titleWidth}) => $titleWidth}px;
`;
const Input = styled.input`
    width: 100%;
    height: ${({ $height }) => $height}px;
    padding: 0 16px;
    border: ${({ $border }) => $border};
    border-radius: 4px;
`;
const Asterisk = styled.span`
  color: ${theme.colors.activeRed};
`;

const StyledInput = ({
    ROW,
    ASTERISK,
    width = 440,
    height = 50,
    titleWidth = 136,
    margin,
    border = `1px solid ${theme.colors.lightGrayBorder}`,
    title = '',
    name,
    value,
    type = 'text',
    onChange,
    onKeyUp,
    maxLength = 200,
    disabled = false,
    ref,
    placeholder
}) => {

    if (ROW) {
        return (
            <Wrapper ROW={ROW} $width={width} $margin={margin}>
                {title && (
                    <RowTitle titleWidth={titleWidth}>
                        {title}
                        {ASTERISK && <Asterisk>*</Asterisk>}
                    </RowTitle>
                )}
                <Input
                    $height={height}
                    $border={border}
                    name={name}
                    value={value}
                    type={type}
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    maxLength={maxLength}
                    ref={ref}
                    disabled={disabled}
                    placeholder={placeholder}
                />
            </Wrapper>
        );
    }

    return (
        <Wrapper $width={width} $margin={margin}>
            {title && (
                <ColumnTitle>
                    {title}
                    {ASTERISK && <Asterisk>*</Asterisk>}
                </ColumnTitle>
            )}
            <Input
                $height={height}
                $border={border}
                name={name}
                value={value}
                type={type}
                onChange={onChange}
                onKeyUp={onKeyUp}
                maxLength={maxLength}
                ref={ref}
                disabled={disabled}
                placeholder={placeholder}
            />
        </Wrapper>
    );
};

export default React.memo(StyledInput);
