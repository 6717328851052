import React, { memo, useEffect, useRef, useState } from 'react';
import { Wrapper, MessageBox, SlideList, MessageText } from './marquee.style';

const Marquee = ({ messageList = [] }) => {
    const [_, setCount] = useState(0);
    const slideRef = useRef(null);
    const textRefs = useRef([]);

    useEffect(() => {
        setTimeout(() => {
            const element = slideRef.current;
            const animate = () => {
                setCount(prevCount => {
                    let newCount = prevCount;

                    if (window.innerWidth > 1920) {
                        newCount += 2.2;
                    } else {
                        newCount += 1.5;
                    }

                    if (newCount > element.scrollWidth / 2) {
                        element.style.transform = `translateX(0)`;
                        newCount = 0;
                    }
                    element.style.transform = `translateX(${-1 * newCount}px)`;
                    return newCount;
                });
                window.requestAnimationFrame(animate);
            }
            animate();
        }, 1200);
    }, [slideRef]);

    return (
        <Wrapper>
            <SlideList ref={slideRef}>
                {[...messageList, ...messageList].map((msg, index) => {
                    const textWidth = textRefs.current?.[index]?.offsetWidth || window.innerWidth;

                    return (
                        <MessageBox
                            key={`${msg?.dlm_id}-${index}-message`}
                            $width={textWidth}>
                            <MessageText ref={el => textRefs.current[index] = el}>
                                {msg?.dlm_text}
                            </MessageText>
                        </MessageBox>
                    );
                })}

            </SlideList>
        </Wrapper>
    );
}

export default memo(Marquee);
