import React, {useContext} from "react";
import { IoTimeOutline } from 'react-icons/io5';
import {DateText, FlexBox, Header, Image, LogoBox, Title} from "./patient.style";
import {getToday} from "../../utils/commons";
import {MainContext} from "../../pages/Main";
import theme from "../../styles/theme";
import logo from '../../assets/images/logo.png';

const PatientHeader = ({ style }) => {
    const { didData } = useContext(MainContext);

    return (
        <Header id="infoHeader">
            <LogoBox>
                <Image src={logo}/>
            </LogoBox>
            <FlexBox
                $flexDirection={style?.flexDirection}
                $alignItems={style?.alignItems}
                $justifyContent={style?.justifyContent}
            >
                <Title
                    $fontSize={didData?.hospital?.hsp_name?.length > 26 ? 30 : 42}
                    $marginBottom={style?.titleMarginTop}
                >
                    {didData?.hospital?.hsp_name || ''}
                </Title>
                <FlexBox $justifyContent='flex-end'>
                    <IoTimeOutline fontSize={36} color={theme.colors.whiteColor} />
                    <DateText>{getToday()}</DateText>
                </FlexBox>
            </FlexBox>
        </Header>
    )
}

export default PatientHeader;
