import styled from "styled-components";

export const BoldText = styled.div`
  font-size: ${({$fontSize}) => ($fontSize ? $fontSize : 24)}px;
  color: ${({$fontColor, theme}) => ($fontColor ? $fontColor : theme.colors.blackColor)};
  font-family: 'AppleSDGothicNeoBold', 'SF Pro Display', sans-serif;
  margin: ${({$margin}) => ($margin ? $margin : 0)};
`;
export const MediumText = styled.div`
  font-size: ${({$fontSize}) => ($fontSize ? $fontSize : 16)}px;
  color: ${({$fontColor, theme}) => ($fontColor ? $fontColor : theme.colors.blackColor)};
  font-family: 'AppleSDGothicNeoMedium', 'SF Pro Display', sans-serif;
  margin: ${({$margin}) => ($margin ? $margin : 0)};
`;
export const RegularText = styled.div`
  font-size: ${({$fontSize}) => ($fontSize ? $fontSize : 16)}px;
  color: ${({$fontColor, theme}) => ($fontColor ? $fontColor : theme.colors.blackColor)};
  font-family: 'AppleSDGothicNeoRegular', 'SF Pro Display', sans-serif;
  margin: ${({$margin}) => ($margin ? $margin : 0)};
`;
export const LightText = styled.div`
  font-size: ${({$fontSize}) => ($fontSize ? $fontSize : 16)}px;
  color: ${({$fontColor, theme}) => ($fontColor ? $fontColor : theme.colors.blackColor)};
  font-family: 'AppleSDGothicNeoLight', 'SF Pro Display', sans-serif;
  margin: ${({$margin}) => ($margin ? $margin : 0)};
`;
