import { gql } from '@apollo/client';

export const REQ_WAITING_PATIENT_LIST = gql`
    query Query($hospitalEmail: String!, $didUniqueId: String!) {
        reqWaitingPatientList(
            hospitalEmail: $hospitalEmail
            did_uniqueId: $didUniqueId
        )
    }
`;
