import React, {useContext, useEffect, useState} from "react";
import {MainContext} from "../../pages/Main";
import styled, {css} from "styled-components";
import theme from "../../styles/theme";
import PatientInfoText from "./PatientInfoText";
import Loader from "../Loader";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
`;
const Title = styled.div`
  width: 100%;
  height: 90px;
  font-size: 42px;
  font-weight: 600;
  color: ${theme.colors.whiteColor};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.deepBlueColor};
`;
const ListBox = styled.div`
  height: calc(100% - 158px);
  //overflow: hidden;
`;
const ListRow = styled.div`
  height: 68px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.listBorderColor};

  &:first-child {
    margin-top: 2px;
  }

  &:nth-child(2n + 1) {
    background-color: ${theme.colors.listBgColor};
  }

  ${({$active}) => $active && css`
    padding: 0 16px;
    border: 4px solid ${theme.colors.activeSkyBlue};
  `};
`;
const Text = styled.div`
  font-size: ${({$fontSize}) => $fontSize}px;
  font-weight: 600;
  margin: ${({$margin}) => $margin ? $margin : 0};
`;
const ContentsFooter = styled.div`
  height: 68px;
  font-size: 28px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.colors.lightSkyBlueColor};
`;
const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;
const EmptyWaitingPerson = styled.div`
  height: 100%;
  font-size: ${({$fontSize}) => $fontSize / 1.3}px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ClinicIntegrationView = ({contentsHeight}) => {
    const {didData, patientData} = useContext(MainContext);

    const [waitingList, setWaitingList] = useState([]);
    const [maxCount, setMaxCount] = useState(null);
    const [pageIndex, setPageIndex] = useState(0);
    const [fontSize, setFontSize] = useState(30);
    const [numberWidth, setNumberWidth] = useState(34);
    const lastPage = Math.ceil(waitingList.length / maxCount) - 1;

    useEffect(() => { // 렌더링 가능한 돔의 높이 구하기
        if (contentsHeight) {
            const listBox = document.getElementById("listBox");
            setMaxCount(Math.floor(listBox.clientHeight / 68)); // 박스 높이 나누기 하나의 행
        }
    }, [contentsHeight]);

    useEffect(() => { // 3초마다 다음 환자 정보 페이지로 이동
        const timer = setInterval(() => {
            if (pageIndex >= lastPage) { // 마지막 페이지를 넘어가면 현재 페이지 초기화
                setPageIndex(0);
            } else { // 마지막 페이지가 아닐 경우 계속 다음페이지로 이동
                setPageIndex(prev => prev + 1);
            }
        }, 3000);
        return () => clearInterval(timer);
    }, [pageIndex, lastPage]);

    useEffect(() => {
        switch (didData.did_standbyPersonFontsize) {
            case '크게':
                setFontSize(42);
                setNumberWidth(46);
                break;
            case '중간':
                setFontSize(36);
                setNumberWidth(40);
                break;
            case '작게':
                setFontSize(30);
                setNumberWidth(34);
                break;
        }
    }, [didData]);

    useEffect(() => {
        if (patientData?.WaitingList?.length >= 0) {
            setWaitingList(patientData?.WaitingList);
        }
    }, [patientData]);

    return (
        <Wrapper>
            <Title>대기 인원</Title>
            <ListBox id="listBox">
                {maxCount ? (
                        waitingList.length === 0
                        ? <EmptyWaitingPerson $fontSize={fontSize}>현재 대기인원이 없습니다.</EmptyWaitingPerson>
                        : waitingList.slice(pageIndex * maxCount, pageIndex * maxCount + maxCount).map((list, index) => (
                                <ListRow
                                    key={`${index}-patient-list`}
                                    $active={pageIndex === 0 && index === 0}
                                >
                                    <PatientInfoText
                                        NUMBER
                                        width={5}
                                        index={pageIndex * maxCount + 1 + index}
                                        list={list}
                                        fontSize={fontSize}
                                        numberWidth={numberWidth}
                                    />
                                    <PatientInfoText
                                        width={didData.did_patExpRatio1}
                                        type={didData.did_patExpress1}
                                        list={list}
                                        fontSize={fontSize}
                                        numberWidth={numberWidth}
                                    />
                                    <PatientInfoText
                                        width={didData.did_patExpRatio2}
                                        type={didData.did_patExpress2}
                                        list={list}
                                        fontSize={fontSize}
                                        numberWidth={numberWidth}
                                    />
                                    <PatientInfoText
                                        width={didData.did_patExpRatio3}
                                        type={didData.did_patExpress3}
                                        list={list}
                                        fontSize={fontSize}
                                        numberWidth={numberWidth}
                                    />
                                    <PatientInfoText
                                        width={didData.did_patExpRatio4}
                                        type={didData.did_patExpress4}
                                        list={list}
                                        fontSize={fontSize}
                                        numberWidth={numberWidth}
                                    />
                                </ListRow>
                            ))
                    ) : <Loader/>
                }
            </ListBox>
            {didData.did_standbyPersonExpression !== '표시안함' && (
                <ContentsFooter>
                    <Text>
                        {didData.did_standbyPersonExpression === '대기 : 00 명' && '대기'}
                    </Text>
                    <FlexBox>
                        <Text $width="auto" $fontSize={fontSize} $fontWeight={600}>{waitingList.length}</Text>
                        <Text $fontSize={fontSize - 8} $margin="0 0 0 6px">명</Text>
                    </FlexBox>
                </ContentsFooter>
            )}
        </Wrapper>
    )
}

export default ClinicIntegrationView;
